import React, { useState } from 'react';

const AllProductsCtx = React.createContext();

export const AllProductsProvider = ({ children }) => {

    const [products, setProducts] = useState(null)

    return (
        <AllProductsCtx.Provider value={{ products, setProducts }}>
            {children}
        </AllProductsCtx.Provider>
    )

}

export default AllProductsCtx;