import React, { useState } from 'react'

const MountCtx = React.createContext()

export const MountProvider = ({ children }) => {

    const [mount, setMount] = useState(false)

    return (
        <MountCtx.Provider value={{ mount, setMount }}>
            {children}
        </MountCtx.Provider>
    )

}

export default MountCtx