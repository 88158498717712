import { forwardRef } from "react";

const Label = (props)=><label {...props}>{props.children}</label>;
const Input = forwardRef((props,ref)=><input ref={ref} {...props} />);
const Button = (props)=><button {...props}>{props.children}</button> 
const Img = (props)=><img {...props} />
const Div = (props)=><div {...props}>{props.children}</div>
const H1 = (props)=><h1 {...props}>{props.children}</h1>
const H2 = (props)=><h2 {...props}>{props.children}</h2>
const H3 = (props)=><h3 {...props}>{props.children}</h3>
const H4 = (props)=><h4 {...props}>{props.children}</h4>
const H5 = (props)=><h5 {...props}>{props.children}</h5>
const H6 = (props)=><h6 {...props}>{props.children}</h6>


export {Label, Input, Button, Img, Div, H1, H2, H3, H4, H5, H6} 