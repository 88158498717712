import React, { useState } from 'react'

const SelectedProductCtx = React.createContext()

export const SelectedProductProvider = ({ children }) => {

    const [selectedProduct, setSelectedProduct] = useState()

    return (
        <SelectedProductCtx.Provider value={{ selectedProduct, setSelectedProduct }}>
            {children}
        </SelectedProductCtx.Provider>
    )

}

export default SelectedProductCtx