import axios from "axios";
import {REACT_APP_API_ZEALINNOVATIONS} from "../env"

const stripeHelpers = {

    fetchClientSecret: async (product_id) => {
        const secretRes = await axios.post(`${REACT_APP_API_ZEALINNOVATIONS}/stripe/purchase/pi`, { product_id:product_id }, {headers:{
            'content-type':'application/json'
        }});

        return {secret: secretRes.data.pi.client_secret, product:secretRes.data.product};
    }
}

export default stripeHelpers;