import axios from "axios";
import {REACT_APP_API_ZEALINNOVATIONS} from "../env"

const productHelpers = {

    get_products: async (system_id) => {
        const products = await axios.get(`${REACT_APP_API_ZEALINNOVATIONS}/stripe/products/get?system_id=${system_id}`, {headers:{
            'content-type':'application/json'
        }});
        return products.data.products;
    },
    get_product: async (product_uuid) => {
        const products = await axios.get(`${REACT_APP_API_ZEALINNOVATIONS}/stripe/products/get?product_uuid=${product_uuid}`, {headers:{
            'content-type':'application/json'
        }});
        return products.data.product;
    }
}

export default productHelpers;